<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP TENANT LIST

type    : view

uses    : header-view
          main-container

route   : /tenants (default child route)
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="app-tenant-list" v-if="tenants">
    <header-view
      title="All Tenants"
      :btnOptions="
        allowTenantCreate
          ? [
              {
                name: 'New Tenant',
                icon: 'mdi-plus',
                action: () => $router.push({name: 'TenantCreate'}),
              },
            ]
          : []
      " />
    <main-container id="tenantTableContainer">
      <div v-if="tenants && tenants.length > 0">
        <v-card>
          <v-card-title>
            <v-icon @click="filterdialog = true">mdi-filter</v-icon>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="QuickSearch"
              single-line
              hide-details></v-text-field>
          </v-card-title>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="filteredTenants"
            class="elevation-1">
            <template v-slot:item="props">
              <tr>
                <td>{{ props.item.tenant_name }}</td>
                <td>{{ props.item.uuid }}</td>
                <td>{{ props.item.contact_phone }}</td>
                <td>{{ props.item.contact_email }}</td>
                <td>{{ props.item.account_status }}</td>
                <td>
                  {{
                    displayDateFormat(
                      props.item.subscription_end_date,
                      dateFormat
                    )
                  }}
                </td>
                <td>
                  <router-link
                    v-if="allowTenantView"
                    :to="{
                      name: 'TenantView',
                      params: {uuid: props.item.uuid},
                    }">
                    View
                  </router-link>
                  <span> | </span>
                  <router-link
                    v-if="allowTenantEdit"
                    :to="{
                      name: 'TenantEdit',
                      params: {uuid: props.item.uuid},
                    }">
                    Edit
                  </router-link>
                  <span v-if="allowTenantManage"> | </span>
                  <a :href="getManageURL(props.item.tenant_url)">Manage</a>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <v-dialog v-model="filterdialog" persistent max-width="600px">
        <v-card color="grey lighten-4" min-width="350px" flat>
          <v-toolbar color="info" flat dark>
            <v-toolbar-title>Filter Tenants</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    label="Tenant Name Contains"
                    v-model="filter.tenant_name"
                    hint=""
                    required></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="Client Phone Contains"
                    v-model="filter.contact_phone"
                    hint=""
                    required></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="Client Email Contains"
                    v-model="filter.contact_email"
                    hint=""
                    required></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <div id="status-filter">
                    <v-select
                      v-model="filter.account_status"
                      :items="filterItems"
                      filled
                      label="Account Status"></v-select>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="filterdialog = false"
              >Apply</v-btn
            >
            <v-btn color="secondary" text @click="clearFilter()">Clear</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </main-container>
  </div>
</template>

<script>
  // packages
  import psl from 'psl';

  // components
  import HeaderView from '@/components/header-view';
  import MainContainer from '@/components/main-container';

  // mixins
  import dateTimeHelperFunctions from '@/mixins/dateTimeHelperFunctions';
  import Localization from '@/mixins/localization';

  // services
  import Tenants from '@/services/Tenants.service.js';

  export default {
    name: 'AppTenantList',
    components: {
      'header-view': HeaderView,
      'main-container': MainContainer,
    },
    mixins: [dateTimeHelperFunctions, Localization],
    data() {
      return {
        search: '',
        filterdialog: false,
        filter: {
          tenant_name: '',
          contact_phone: '',
          contact_email: '',
          account_status: '',
        },
        filterItems: [
          {
            text: 'Show All',
            value: '',
          },
          {
            text: 'Active Only',
            value: 'Active',
          },
          {
            text: 'Inactive Only',
            value: 'Inactive',
          },
        ],
        headers: [
          {
            text: 'Tenant Name',
            align: 'left',
            sortable: false,
            value: 'tenant_name',
          },
          {
            text: 'ID',
            value: 'uuid',
          },
          {
            text: 'Contact Phone',
            value: 'contact_phone',
          },
          {
            text: 'Contact Email',
            value: 'contact_email',
          },
          {
            text: 'Tenant Status',
            value: 'account_status',
          },
          {
            text: 'Subscription End Date',
            value: 'subscription_end_date',
          },
          {
            text: 'View/Edit',
            value: 'links',
          },
        ],
        tenants: null,
        allowTenantCreate: false,
        allowTenantView: false,
        allowTenantEdit: false,
        allowTenantManage: false,
        allowTenantList: false,
      };
    },
    computed: {
      dateFormat() {
        return this.$auth.tenantProfile.date_format;
      },
      filteredTenants: function () {
        // `this` points to the vm instance
        return this.tenants.filter((record) => {
          let pass = true;
          if (
            this.filter.tenant_name &&
            !record.tenant_name.includes(this.filter.tenant_name)
          ) {
            pass = false;
          }
          if (
            this.filter.contact_phone &&
            !record.contact_phone.includes(this.filter.contact_phone)
          ) {
            pass = false;
          }
          if (
            this.filter.contact_email &&
            !record.contact_email.includes(this.filter.contact_email)
          ) {
            pass = false;
          }
          if (
            this.filter.account_status &&
            record.account_status != this.filter.account_status
          ) {
            pass = false;
          }
          return pass;
        });
      },
    },
    created() {
      this.getAllowed();
    },
    methods: {
      async getAllowed() {
        this.allowTenantCreate = this.$auth.isAllowed('tenants', 'create');
        this.allowTenantView = this.$auth.isAllowed('tenants', 'view');
        this.allowTenantEdit = this.$auth.isAllowed('tenants', 'edit');
        this.allowTenantManage = await this.$auth.isSystemAdmin();
        this.allowTenantList = this.$auth.isAllowed('tenants', 'list');

        // make sure we are allowed
        if (this.allowTenantView) {
          // Get the access token from the auth wrapper
          const accessToken = await this.$auth.getTokenSilently();

          // get tenants
          this.tenants = await Tenants.getTenants({}, accessToken);
        }
      },
      getManageURL(tenant_url) {
        // parse domain with public suffix list package
        var parsed_url = psl.parse(window.location.hostname);

        // return the protocol + new tenant subdomain + root domain
        var tenant_manage_url =
          window.location.protocol +
          '//' +
          tenant_url +
          '.' +
          parsed_url.domain;

        return tenant_manage_url;
      },
      clearFilter() {
        this.filter.tenant_name = '';
        this.filter.contact_phone = '';
        this.filter.contact_email = '';
        this.filter.account_status = '';
        this.filterdialog = false;
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
</style>
